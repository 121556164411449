body {
  min-width: 1024px;
  overflow-x: initial;
}

html body {
  .jqstooltip,
  .flotTip {
    width: auto !important;
    height: auto !important;
    background: $gray-900;
    color: $white;
    padding: 5px 10px;
  }
}

body .jqstooltip {
  border-color: transparent;
  border-radius: 60px;
}

// Tooltip for the chartist chart charts
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  border-radius: $border-radius;
  padding: 10px 20px;
  background: $info;
  color: $white;
  ext-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.chartist-tooltip:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: $info;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

.c3-chart-arcs-title {
  fill: $text-muted;
}

.c3 line,
.c3 path {
  stroke: $gray-200;
}
.c3-chart-arc path {
  stroke: transparent;
}

.ReactModal__Overlay {
  z-index: 100;
  .modal-header > span {
    width: 100%;
  }
}
.react-bootstrap-table-page-btns-ul {
  float: right;
}

// Form Steps

ol.progtrckr {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: '\00a0\00a0';
}

ol.progtrckr li:before {
  position: relative;
  bottom: -65px;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: '\039F';
  color: silver;
  background-color: white;
  width: 20px;
  line-height: 20px;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: '\2022';
  color: white;
  background-color: #cccccc;
  width: 20px;
  line-height: 20px;
  border-radius: 50px;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: '\2713';
  color: white;
  background-color: #5cb85c;
  width: 20px;
  line-height: 20px;
  border-radius: 50px;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.table-filter-con {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.table-filter-con input {
  width: auto !important;
}

.date-filter-style input {
  height: 28.38px;
}

.table-checkbox-con {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.clickable-link {
  cursor: pointer;
  color: dodgerblue;
  font-weight: 400;
}

.clickable-link:hover {
  color: darkblue;
}

.bc ol {
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}

.bc a {
  color: #007bff !important;
}

.modal-primary {
  background-color: #1e88e5 !important;
}

.modal-primary h5 {
  color: white !important;
}

.cf-label {
  font-weight: 400 !important;
}

.align-header {
  text-align: initial !important;
}

.cursor-pointer {
  cursor: pointer;
}

.auto-width {
  width: auto !important;
}

.br-filter {
  color: #11646f;
  background-color: #d3f3f7;
  border-color: #c1eef4;
  padding: 0.5rem;
  padding: 0.75rem 1.25rem 0.75rem 0px;
  border: 1px solid transparent;
  border-radius: 2px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

// .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link i {
//   width: unset !important;
// }

.custom-sidebar:hover,
.sidebar-link:hover {
  background: #21c1d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  //width: 240px;
}

.selected.sidebar-item {
  background: #1fb3c7 !important;
  color: #fff !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}

.selected.sidebar-item > .sidebar-link {
  color: #fff !important;
}

.page-content.container-fluid {
  padding: 10px !important;
}

.breadcrumb {
  padding-top: 0px !important;
}

.parent-nav {
  line-height: 25px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 500;
  box-shadow: '0 0 10px rgb(0 0 0 / 20%)';
  padding: 8px 35px 8px 8px;
  background: #21c1d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  cursor: pointer;
}

.parent-nav span {
  font-size: 0.75rem !important;
}

.parent-nav i {
  font-size: 21px !important;
}

.sidebar-link span {
  font-size: 0.75rem;
}

.breadcrumb {
  font-weight: bold;
}

// .navbar-header, .navbarbg {
//   background-color: #21c1d6 !important;
// }

.navbar-header.expand-logo {
  line-height: 69px !important;
}

.navbar-header.expand-logo {
  display: flex;
  align-items: center;
  // justify-content: center;
  div {
    color: darkslategray;
    font-weight: 600;
  }
}

.custom-hr {
  border-top: 1px solid rgb(0, 0, 0) !important;
  opacity: 0.5;
}

label {
  font-weight: 600;
}

.sidebar-item > a {
  display: block;
  height: 100%;
  width: 100%;
  padding: 8px 0px 8px 13px;
}

.custom-control-label::before,
.custom-control-label::after {
  top: -0.0625rem !important;
}

.custom-radio-input label {
  margin-bottom: 1rem !important;
}

.rt-tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.required {
  color: #fc4b6c;
  font-weight: 600;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.width-auto {
  width: auto !important;
}

.form-check-input {
  margin-top: 0 !important;
}

.sidebar-item:hover > a > span,
.selected.sidebar-item > a > span {
  color: #595959 !important;
}

.custom-addon-icon {
  height: calc(1.5em + 0.5rem + 2px) !important;
  font-size: 0.76563rem !important;
  line-height: 1.5 !important;
}

.alert-success {
  background-color: #1e88e5 !important;
  border-radius: 0 !important;
}

.alert-success > h5,
.alert-fail > h5 {
  color: white !important;
}

.alert-fail {
  background-color: #fc4b6c !important;
  border-radius: 0 !important;
}

.rs-alert {
  position: absolute;
  top: -15px;
  right: 0;
  width: auto;
  height: auto;
}

.modal-fullwidth {
  max-width: unset !important;
  padding: 1.75rem;
}

.hover-cell:hover {
  background-color: rgb(221, 255, 255);
}

.transfer .rt-tbody .rt-tr-group:hover {
  background-color: rgb(221, 255, 255);
}

.input-style {
  height: 28.38px;
  border: 1px solid #e9ecef;
  border-radius: 2px;
}

.letter-filter {
  width: 20px;
  border: 0.5px solid;
  text-align: center;
  font-size: 0.75rem;
  padding: 5px 0px;
  font-weight: 500;
  border-radius: 3px;
  margin-right: 0.25rem;
  cursor: pointer;
}

.letter-filter:hover {
  transform: scale(1.1);
  border: 1px solid #21c1d6;
  color: #21c1d6;
}

.tooltip-on-hover {
  visibility: hidden;
}

.tooltip-on-hover:hover {
  visibility: visible;
}

th {
  z-index: 0 !important; // to prevent material-table header appearing on top of sidebar
}

.MuiTableRow-footer {
  table-layout: fixed;
  display: table !important;
}

.MuiTableRow-root {
  height: unset !important;
}

.MuiCheckbox-root {
  padding: 0px 0px 0px 0px !important;
}

.eth-summary {
  width: calc(100% / 8);
  padding: 2px 10px 2px 10px;
  background-color: gainsboro;
}

.de-summary {
  width: calc(100% / 10);
  padding: 2px 10px 2px 10px;
  background-color: gainsboro;
}

.tr-summary {
  padding: 2px 10px 2px 10px;
  background-color: gainsboro;
}

.MuiTableHead-root .MuiCheckbox-root {
  padding-left: 0px !important;
}

.slim-table {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.tbl-filter table tbody > tr:first-child td {
  padding: 1px 5px;
}

.tbl-filter .MuiTableCell-root {
  font-size: 11px !important;
}

.rt-tbody {
  overflow-y: hidden !important;
}

#new-window-container > div {
  padding: 1rem 0.5rem;
}

.confirm-delete {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 1rem;
}

.confirm-delete > div {
  width: 300px;
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;
  flex-flow: column;
  background-color: white;
  border-radius: 5px;
  justify-content: center;
}

.mb-3.custom-mt {
  overflow: scroll;
}

.mb-3.custom-mt table {
  width: auto;
}

.mb-3.custom-mt > div:nth-child(3) {
  width: fit-content;
}

.mb-3.custom-mt > div:nth-child(3) > div:nth-child(2),
.mb-3.custom-mt > div:nth-child(3) > div:nth-child(2) > div > div {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.select__input {
  border: none !important;
}

#new-window-container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

input, select, textarea {
  border: 1px solid black !important;
}

@media screen and (max-width: 768px) {
  .left-sidebar > ul > li,
  .left-sidebar > ul > li > div {
    height: 40px;
    position: relative;
  }

  .left-sidebar > ul > li > div > div {
    display: none;
  }

  .left-sidebar > ul > li > div:hover > div {
    display: block !important;
  }

  .left-sidebar > ul > li > div > div {
    width: 150px;
    position: absolute;
    top: 0;
    right: -130px;
    background: #21c1d6;
    border-radius: 5px;
    z-index: 1;
  }

  .left-sidebar > ul > li > div > div > ul {
    background: #21c1d6 !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .left-sidebar > ul > li > div > div > ul a {
    padding: 0px 15px;
    color: white;
  }
}

.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: 1px solid rgb(229, 229, 229) !important;
}

.ReactTable .rt-thead.-header {
  background-color: gainsboro;
}

.tbl-filter {
  color: rgba(0, 0, 0, 0.87);
}

.MuiCheckbox-root {
  display: block !important;
}

@media screen and (max-width: 1000px) {
  .tbl-filter table thead tr th,
  .tbl-filter table tbody tr td,
  .tbl-filter .rt-td,
  .tbl-filter .rt-th,
  .tr-summary {
    font-size: 10px !important;
  }

  .tbl-filter .rt-td {
    text-overflow: unset;
    white-space: initial;
  }

  .mui-tbl-con {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// text animation
@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400');

.msg-con {
  display: flex;
  width: 100%;
  height: 60vh;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: #263238;
  color: #fff;
  font-size: 50px;
  font-family: 'Fira Mono', monospace;
  letter-spacing: -2px;
  border-radius: 5px;
}

.admin-msg {
  animation: glitch 1s linear infinite;
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

.admin-msg:before,
.admin-msg:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.admin-msg:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.admin-msg:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}

.modal[role="dialog"] .modal-body {
  white-space: pre-line;
}

:root {
  --code-color: darkred;
  --code-bg-color: #aaaaaa;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}

pre {
  color: var(--code-color);
  font-size: var(--code-font-size);
  line-height: var(--code-line-height);
  background-color: var(--code-bg-color);
}

.code-block {
  max-height: 100px;
  overflow: auto;
  padding: 8px 7px 5px 15px;
  margin: 0px 0px 0px 0px;
  border-radius: 7px;
}

::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

table.MuiTable-root tr:hover {
  background: #eaeaea;
}

table.MuiTable-root tr.active {
  background: #ffb22b;
}

.mdi-delete {
  font-size: 18px;
}